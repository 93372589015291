import { AcAvatar, AcDivider, AcTypography } from '@components'
import { AcIntlDDMMYYYYHHMM } from '@helpers/ac-intl-datetime.helper'
import { ITemplateInfo } from '@typings'

import { LABELS } from '@constants'

import styles from './ac-template-profile-header.module.scss'

export const AcTemplateProfileHeader = ({
  customer,
  profile,
}: {
  customer?: Nullable<ITemplateInfo['customer']>
  profile: ITemplateInfo['profile']
}) => {
  const account_manager_name = customer?.account_manager?.name || ''
  const updated_at = profile?.updated_at
    ? AcIntlDDMMYYYYHHMM(new Date(profile?.updated_at))
    : '-'

  return (
    <div className={styles['ac-template-profile-header']}>
      <AcDivider color="neutral-200" />
      <AcTypography weight="semibold">{customer?.name}</AcTypography>
      <div className={styles['ac-template-profile-header__account']}>
        <AcAvatar
          src={customer?.account_manager?.avatar || void 0}
          className={styles['ac-template-profile-header__account__avatar']}
          alt={LABELS.PROFILE_AVATAR}
          text={(account_manager_name || '').substring(0, 2)}
        />
        <div className={styles['ac-template-profile-header__account__text']}>
          <AcTypography
            size="xs"
            weight="light"
            element="span"
            color="neutral-700">
            {LABELS.ACCOUNT}
          </AcTypography>
          <AcTypography
            element="span"
            size="sm">
            {account_manager_name}
          </AcTypography>
        </div>
      </div>
      <AcTypography
        color="neutral-500"
        size="sm">
        {LABELS.LAST_UPDATED_BY}
      </AcTypography>
      <div className={styles['ac-template-profile-header__updated']}>
        <AcTypography
          weight="semibold"
          color="neutral-500"
          element="span"
          size="xs">
          {profile?.user?.name}
        </AcTypography>
        <AcTypography
          color="neutral-500"
          element="span"
          size="xs"
          weight="light">
          {updated_at}
        </AcTypography>
      </div>
    </div>
  )
}
